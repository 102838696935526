<template>
  <div class="w-100">
    <el-button
      class="btn mb-2 mt-2"
      type="primary"
      @click="createPaymentMethod"
    >
      <span class="btn-inner--icon">
        <i class="fa fa-plus"></i>
        {{ $t('WALLET.ADD_PAYMENTMOTHOD') }}
      </span>
    </el-button>

    <el-card>
      <payment-methods-list
        ref="paymentMethodsList"
        :holder-type="holderType"
        :holder-id="holderId"
        @editPaymentMethod="editPaymentMethod($event)"
        @paymentmethodDeleted="refreshData"
      />
    </el-card>

    <el-drawer
      :title="operationType=='edit'? $t('WALLET.EDIT_PAYMENTMETHOD'):$t('WALLET.ADD_PAYMENTMOTHOD')"
      :visible.sync="showPaymentMethodForm"
      direction="rtl"
      size="50%"
      append-to-body>
      <div class="paymentmethod-form px-5">
        <payment-method-form 
          :holder-type="holderType"
          :holder-id="holderId"
          :operation-type="operationType" 
          :paymentMethodToEdit="currentPaymentMethod" 
          @paymentmethodCreated="refreshData"
        />
      </div>
    </el-drawer>
  
  </div>
</template>

<script>
import PaymentMethodsList from "./lists/PaymentMethodsList.vue";
import PaymentMethodForm from './forms/PaymentMethodForm.vue';

export default {
  name: "PaymentMethods",
  components: {
    PaymentMethodsList,
    PaymentMethodForm
  },
  props: {
    holderType: {
      type: String,
      require: true,
    },
    holderId: {
      type: [String,Number],
      require: true,
    },
  },
  data() {
    return {
      operationType: 'create',
      currentPaymentMethod:null,
      showPaymentMethodForm:false
    };
  },
  methods: {
    createPaymentMethod(){
      this.operationType = 'create';
      this.showPaymentMethodForm = true;
    },

    editPaymentMethod(paymentMethod){
      this.operationType = 'edit';
      this.currentPaymentMethod = paymentMethod;
      this.showPaymentMethodForm = true;
    },

    refreshData() {
      this.showPaymentMethodForm = false;
      // Actualiser la liste des PaymentMethods
      if (this.$refs.paymentMethodsList) {
        this.$refs.paymentMethodsList.refreshList();
      }
    },
  },
};
</script>
