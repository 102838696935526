<template>
  <div class="no-wallet-page m-auto">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <div class="has-wallet">
      <div class="el-empty__image">
        <svg
          viewBox="0 0 79 86"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <linearGradient
              id="linearGradient-1-1"
              x1="38.8503086%"
              y1="0%"
              x2="61.1496914%"
              y2="100%"
            >
              <stop stop-color="#FCFCFD" offset="0%"></stop>
              <stop stop-color="#EEEFF3" offset="100%"></stop>
            </linearGradient>
            <linearGradient
              id="linearGradient-2-1"
              x1="0%"
              y1="9.5%"
              x2="100%"
              y2="90.5%"
            >
              <stop stop-color="#FCFCFD" offset="0%"></stop>
              <stop stop-color="#E9EBEF" offset="100%"></stop>
            </linearGradient>
            <rect id="path-3-1" x="0" y="0" width="17" height="36"></rect>
          </defs>
          <g
            id="Illustrations"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="B-type" transform="translate(-1268.000000, -535.000000)">
              <g id="Group-2" transform="translate(1268.000000, 535.000000)">
                <polygon
                  id="Rectangle-Copy-14"
                  fill="#E5E7E9"
                  transform="translate(27.500000, 51.500000) scale(1, -1) translate(-27.500000, -51.500000) "
                  points="13 58 53 58 42 45 2 45"
                ></polygon>
                <g
                  id="Group-Copy"
                  transform="translate(34.500000, 31.500000) scale(-1, 1) rotate(-25.000000) translate(-34.500000, -31.500000) translate(7.000000, 10.000000)"
                >
                  <polygon
                    id="Rectangle-Copy-10"
                    fill="#E5E7E9"
                    transform="translate(11.500000, 5.000000) scale(1, -1) translate(-11.500000, -5.000000) "
                    points="2.84078316e-14 3 18 3 23 7 5 7"
                  ></polygon>
                  <polygon
                    id="Rectangle-Copy-11"
                    fill="#EDEEF2"
                    points="-3.69149156e-15 7 38 7 38 43 -3.69149156e-15 43"
                  ></polygon>
                  <rect
                    id="Rectangle-Copy-12"
                    fill="url(#linearGradient-1-1)"
                    transform="translate(46.500000, 25.000000) scale(-1, 1) translate(-46.500000, -25.000000) "
                    x="38"
                    y="7"
                    width="17"
                    height="36"
                  ></rect>
                  <polygon
                    id="Rectangle-Copy-13"
                    fill="#F8F9FB"
                    transform="translate(39.500000, 3.500000) scale(-1, 1) translate(-39.500000, -3.500000) "
                    points="24 7 41 7 55 -3.63806207e-12 38 -3.63806207e-12"
                  ></polygon>
                </g>
                <rect
                  id="Rectangle-Copy-15"
                  fill="url(#linearGradient-2-1)"
                  x="13"
                  y="45"
                  width="40"
                  height="36"
                ></rect>
                <g
                  id="Rectangle-Copy-17"
                  transform="translate(53.000000, 45.000000)"
                >
                  <mask id="mask-4-1" fill="white">
                    <use xlink:href="#path-3-1"></use>
                  </mask>
                  <use
                    id="Mask"
                    fill="#E0E3E9"
                    transform="translate(8.500000, 18.000000) scale(-1, 1) translate(-8.500000, -18.000000) "
                    xlink:href="#path-3-1"
                  ></use>
                  <polygon
                    id="Rectangle-Copy"
                    fill="#D5D7DE"
                    mask="url(#mask-4-1)"
                    transform="translate(12.000000, 9.000000) scale(-1, 1) translate(-12.000000, -9.000000) "
                    points="7 0 24 0 20 18 -1.70530257e-13 16"
                  ></polygon>
                </g>
                <polygon
                  id="Rectangle-Copy-18"
                  fill="#F8F9FB"
                  transform="translate(66.000000, 51.500000) scale(-1, 1) translate(-66.000000, -51.500000) "
                  points="62 45 79 45 70 58 53 58"
                ></polygon>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <p class="no-wallet-text">{{ $t("WALLET.NO_WALLET_MESSAGE") }}</p>
      <el-button
        type="success"
        class="align-items-center mb-4"
        round
        :disabled="loading"
        @click="createWallet"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ $t("WALLET.CREATE_WALLET") }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoWallet",
  props: {
    holderType: {
      type: String,
      require: true,
    },
    holderId: {
      type: [String,Number],
      require: true,
    },
  },
  data() {
    return {
      createdWallet: {},
      loading: false,
    };
  },
  methods: {
    async createWallet() {
      this.loading = true;
      let data = {
        holder_type: this.holderType,
        holder_id: this.holderId,
      };
      try {
        // Appel à l'API pour la création
        const response = await this.$axios.post(
          '/elixio-wallet/create-wallet',
          data
        );
        if (response.data.success) {
          this.$emit("onWalletCreated", response.data);
          this.$notify({
            title: this.$t("WALLET.SUCCESS"),
            message: this.$t("WALLET.WALLET_CREATED"),
            type: "success",
          });
        } else {
          this.$notify.error({
            title: this.$t("WALLET.ERROR"),
            message: this.$t("WALLET.WALLET_CREATION_FAILED"),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
