<template>
  <div>
    <div class="page-wrapper-table">
      <h3>{{ $t("WALLET.PAYMENTMETHODS_LIST") }}</h3>
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('WALLET.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <base-input
          v-model="query"
          type="search"
          prepend-icon="fas fa-search"
          :placeholder="$t('WALLET.SEARCH')"
          clearable
        />
        <base-button
          class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm mb-4"
          icon
          size="sm"
          @click="resetFilters"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-undo"></i>
          </span>
        </base-button>
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="paymentMethods"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <!-- <el-table-column
              :label="$t('WALLET.NUMERO')"
              prop="id"
              sortable="custom"
              min-width="50"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.id ?? "" }}
                </span>
              </template>
            </el-table-column> -->

            <el-table-column
              :label="$t('WALLET.DETAILS')"
              prop="status"
              min-width="350"
            >
              <template v-slot="{ row }">
                <object-details :data="getDetails(row.details)" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.PAYMENT_METHOD_HOLDER')"
              prop="holder"
              min-width="300"
            >
              <template v-slot="{ row }">
                <object-details :data="row.details.holder" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.SIGNERS')"
              prop="status"
              min-width="300"
            >
              <template v-slot="{ row }">
                <object-details
                  :data="getSigners(row.signers)"
                  :with-trad="false"
                />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.TYPE')"
              prop="type"
              sortable="custom"
              min-width="100"
            >
              <template v-slot="{ row }">
                <span>
                  {{ $t(`WALLET.${row.type}`) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.TRANSACTION_CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="150"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at) }}
              </template>
            </el-table-column>

            <el-table-column
              fixed="right"
              min-width="120"
              v-if="
                currentUserCan($permissions.PERM_EDIT_WALLET) ||
                currentUserCan($permissions.PERM_DELETE_WALLET)
              "
            >
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('WALLET.EDIT')"
                  placement="top"
                  v-if="currentUserCan($permissions.PERM_EDIT_WALLET)"
                >
                  <a
                    type="text"
                    @click="editPaymentMethod(row)"
                    class="table-action table-action-edit"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('WALLET.DELETE')"
                  placement="top"
                  v-if="currentUserCan($permissions.PERM_DELETE_WALLET)"
                >
                  <a
                    type="text"
                    @click="deletePaymentMethod(row.id)"
                    class="table-action table-action-delete"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("WALLET.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("WALLET.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import BasePagination from "../atoms/BasePagination.vue";
import ObjectDetails from "../atoms/ObjectDetails.vue";

export default {
  name: "PaymentMethodsList",

  props: {
    holderType: {
      type: String,
      require: true,
    },
    holderId: {
      type: [String, Number],
      require: true,
    },
  },

  components: {
    BasePagination,
    ObjectDetails,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      paymentMethods: [],
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    currentUserCan(permission) {
      //return this.$currentUserCan(permission);
      return true;
    },
    resetFilters() {
      this.query = null;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    getDetails(details) {
      return {
        account_number: details.account_number,
        bank_code: details.bank_code,
        iban: details.iban,
      };
    },

    editPaymentMethod(paymentMethod) {
      this.$emit("editPaymentMethod", paymentMethod);
    },

    getSigners(signers) {
      return signers.reduce((acc, signer) => {
        const fullName = `${signer.firstname} ${signer.lastname}`;
        acc[fullName] = signer.email;
        return acc;
      }, {});
    },

    async getList() {
      try {
        this.loading = true;
        let params = {
          holder_type: this.holderType,
          holder_id: this.holderId,
          sort: this.sort,
          "filter.search": this.query,
          "page.number": this.pagination.currentPage,
          "page.size": this.pagination.perPage,
        };
        const response = await this.$axios.get(
          "/elixio-wallet/payment-methods",
          { params }
        );
        this.paymentMethods = response.data.data;
        this.total = response.data.total;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deletePaymentMethod(paymentMethodId) {
      try {
        // Afficher une confirmation avant de supprimer
        const confirm = await this.$confirm(
          this.$t("WALLET.CONFIRM_DELETE_MESSAGE"),
          this.$t("WALLET.CONFIRM_DELETE_TITLE"),
          {
            confirmButtonText: this.$t("WALLET.DELETE_CONFIRM_BUTTON"),
            cancelButtonText: this.$t("WALLET.DELETE_CANCEL_BUTTON"),
            type: "warning",
          }
        );

        if (confirm) {
          this.loading = true;
          const response = await this.$axios.delete(
            `/elixio-wallet/payment-methods/${paymentMethodId}`
          );

          if (response.data.success) {
            this.$emit("paymentmethodDeleted", paymentMethodId);
            this.$notify({
              title: this.$t("WALLET.SUCCESS"),
              message: this.$t("WALLET.PAYMENT_METHOD_DELETED"),
              type: "success",
            });
          } else {
            this.$notify.error({
              title: this.$t("WALLET.ERROR"),
              message: this.$t("WALLET.PAYMENT_METHOD_DELETE_FAILED"),
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    refreshList() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>
