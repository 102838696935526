<template>
  <badge :type="badgeType">
    {{ $t("WALLET." + status) }}
  </badge>
</template>

<script>
import {
  STATUS_DRAFT,
  STATUS_AWAITING_SIGNATURE,
  STATUS_SIGNATURE_DELINED,
  STATUS_AWAITING_ADMIN_VALIDATION,
  STATUS_ADMIN_REFUSED,
  STATUS_PENDING,
  STATUS_BANK_REFUSED,
  STATUS_COMPLETED,
  TRANSACTION_STATUS_LIST,
} from "../../constants/common";

export default {
  name: "StatusBadge",
  props: {
    status: {
      type: String,
      required: true,
      validator: function (value) {
        return TRANSACTION_STATUS_LIST.includes(value);
      },
    },
  },
  computed: {
    badgeType() {
      switch (this.status) {
        case STATUS_DRAFT:
          return "secondary";
        case STATUS_AWAITING_SIGNATURE:
          return "primary";
        case STATUS_COMPLETED:
          return "success";
        case STATUS_SIGNATURE_DELINED:
        case STATUS_ADMIN_REFUSED:
        case STATUS_BANK_REFUSED:
          return "danger";
        case STATUS_AWAITING_ADMIN_VALIDATION:
        case STATUS_PENDING:
          return "warning";
        default:
          return "default";
      }
    },
  },
};
</script>
