<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("USERS.USERS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openUserCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_USERS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{ $t("USERS.ADD_USER") }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_USERS)"
              :objectType="'users'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <user-list-table
        @onViewUser="openUserViewModal"
        @onEditUser="openUserEditModal"
        @onDeleteUser="deleteUser"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewUserModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewUserModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'USER_VIEW'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("USERS.VIEW") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openUser"
                :objectType="'users'"
                :objectId="openUser.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button v-if="openUser.active==0" class="activate" @click="activateUser()">
                  <i class="ni ni-check-bold icon-shape-success"></i>
                  <span>{{ $t("USERS.ACTIVATE") }}</span>
                </button>
                <button v-if="openUser.active==1" class="deactivate" @click="deactivateUser()">
                  <i class="ni ni-fat-remove icon-shape-danger"></i>
                  <span>{{ $t("USERS.DEACTIVATE") }}</span>
                </button>
                <button class="edit" @click="openUserEditModal(openUser)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteUser(openUser)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeUserModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-user-page ref="ViewUserComponent" v-if="openUser" :userId="openUser.id" />
          </div>
        </div>
      </div>

      <div
        v-if="isEditUserModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditUserModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'USER'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("USERS.EDIT_USER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openUserViewModal(openUser)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeUserModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-user-page
              v-if="openUser"
              :userId="openUser.id"
              @onViewUser="openUserViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddUserModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddUserModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'USER'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("USERS.ADD_USER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeUserModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-user-page @onViewUser="openUserViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import UserListTable from "./partials/UserListTable.vue";
import EditUserPage from "./components/EditUserComponent.vue";
import AddUserPage from "./components/AddUserComponent.vue";
import ViewUserPage from "./components/ViewUserComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    UserListTable,
    NotificationSubscription,
    EditUserPage,
    AddUserPage,
    ViewUserPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const userId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewUserModalOpened = false;
    let isEditUserModalOpened = false;
    let isAddUserModalOpened = false;
    let openUser = null;
    if (userId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewUserModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditUserModalOpened = true;
      }
      openUser = { id: userId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddUserModalOpened = true;
    }
    return {
      isViewUserModalOpened: isViewUserModalOpened,
      isEditUserModalOpened: isEditUserModalOpened,
      isAddUserModalOpened: isAddUserModalOpened,
      openUser: openUser,
      renderKey: 1,
    };
  },

  methods: {
    deactivateUser(){
      if (this.$refs.ViewUserComponent) {
        this.$refs.ViewUserComponent.deactivateUser();
        this.renderKey++;
      }
    },
    activateUser(){
      if (this.$refs.ViewUserComponent) {
        this.$refs.ViewUserComponent.activateUser();
        this.renderKey++;
      }
    },
    openUserCreateModal() {
      this.closeUserModal();
      this.isAddUserModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Users",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openUserViewModal(user, reRender = false) {
      this.closeUserModal();
      this.openUser = user;
      this.isViewUserModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Users",
          query: { id: this.openUser.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openUserEditModal(user) {
      this.closeUserModal();
      this.openUser = user;
      this.isEditUserModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Users",
          query: { id: this.openUser.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeUserModal() {
      this.isAddUserModalOpened = false;
      this.isViewUserModalOpened = false;
      this.isEditUserModalOpened = false;
      this.openUser = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Users",
          query: {},
        }).href
      );
    },

    async deleteUser(user) {
      const confirmation = await swal.fire({
        title: this.$t("USERS.DELETE_THIS_USER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      let me = await this.$store.getters["profile/me"];

      if (user.id === me.id) {
        swal.fire({
          title: this.$t("USERS.CANNOT_SELF_DELETE"),
          buttonsStyling: false,
          showCancelButton: false,
          confirmButtonText: this.$t("COMMON.YES"),
          confirmButtonClass: "btn btn-primary",
        });
        return;
      }

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("users/destroy", user.id);
          this.renderKey++;
          this.closeUserModal();
          this.$notify({
            type: "success",
            message: this.$t("USERS.USER_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
