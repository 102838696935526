<template>
  <div class="content-inputs">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <el-form
      ref="siteForm"
      :rules="rules"
      :model="transaction"
      label-position="left"
      @submit.prevent="handleSubmit"
    >
      <div class="transaction-amount">
            <h3>{{ $t("WALLET.TRANSACTION") }}</h3>
        <el-divider />
        <el-form-item :label="$t('WALLET.TRANSACTION_AMOUNT')" prop="amount">
          <el-input-number
            v-model="transaction.amount"
            :precision="wallet_decimal_places"
            :step="minAmount"
            :max="MAX_AMOUNT"
          />
        </el-form-item>
        <el-form-item :label="$t('WALLET.DEPOSIT_LABEL')" prop="label">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            :placeholder="$t('WALLET.DEPOSIT_LABEL')"
            v-model="transaction.meta.label"
          >
          </el-input>
        </el-form-item>
      </div>
      <div class="paymentmethod mt-5">
        <el-form-item label="" prop="meta.payment_method_id">
          <div class="row d-flex align-items-center">
            <div class="col-10">
              <h3>{{ $t("WALLET.PAYMENTMETHOD") }}</h3>
            </div>
            <div class="col-2">
              <el-button type="primary" icon="el-icon-plus" circle @click="showPaymentMethodForm=true"></el-button>
            </div>
          </div>
        </el-form-item>
        <el-divider />
        <div class="list-card mb-5 p-3">
          <payment-methods-selection-list
            ref="paymentMethodsSelectionList"
            :holder-type="wallet.holder_type"
            :holder-id="wallet.holder_id"
            @selected="setPMid($event)"
          />
        </div>
      </div>
      <el-button
        type="success"
        class="align-items-center mb-5"
        round
        :disabled="loading || processing"
        @click="handleSubmit"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
        {{
          transaction.id
            ? $t("WALLET.EDIT_TRANSACTION")
            : $t("WALLET.CONTINUE_TRANSACTION")
        }}
      </el-button>
    </el-form>
    
    <el-drawer
      :title="$t('WALLET.ADD_PAYMENTMOTHOD')"
      :visible.sync="showPaymentMethodForm"
      direction="rtl"
      size="45%"
      append-to-body>
      <div class="paymentmethod-form px-5">
        <payment-method-form 
          :holder-type="wallet.holder_type"
          :holder-id="wallet.holder_id"
          @paymentmethodCreated="refreshData"
        />
      </div>
    </el-drawer>

  </div>
</template>
<script>
import { MAX_AMOUNT,MIN_AMOUNT } from "../../constants/common";
import PaymentMethodsSelectionList from "../lists/PaymentMethodsSelectionList.vue";
import PaymentMethodForm from './PaymentMethodForm.vue';

export default {
  name: "TransactionForm",
  components: {
    PaymentMethodsSelectionList,PaymentMethodForm
  },
  props: {
    wallet: {
      type: Object,
      required: true,
      default: () => {
        return {
          holder_type: "",
          holder_id: "",
          slug: "",
        };
      },
    },
    transactionType: {
      type: String,
      default: "deposit",
    },
  },
  data() {
    return {
      MAX_AMOUNT: MAX_AMOUNT,
      minAmount:MIN_AMOUNT,
      showPaymentMethodForm:false,
      transaction: {
        holder_type: "",
        holder_id: "",
        slug: "",
        amount: "",
        meta: {
          label: "",
          payment_method_id: "",
        },
        slug: "",
      },
      loading: false,
      processing: false,
      editing: false,
      rules: {
        'meta.payment_method_id': [
          {
            required: true,
            message: this.$t("WALLET.SELECT_PAYMENT_METHOD"),
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: this.$t("WALLET.TRANSACTION_AMOUNT_REQUIRED"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value < this.minAmount) {
                callback(
                  new Error(
                    this.$t("WALLET.TRANSACTION_AMOUNT_MINIMUM", {
                      minimum_amount: this.wallet.meta.currency_symbol+this.minAmount
                    })
                  )
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (
                this.transactionType == "withdraw" &&
                value > this.wallet.balance
              ) {
                callback(new Error(this.$t("WALLET.INSUFFICIENT_FUNDS")));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    refreshData(){
      this.showPaymentMethodForm = false;
      // Actualiser la liste des PaymentMethods
      if (this.$refs.paymentMethodsSelectionList) {
        this.$refs.paymentMethodsSelectionList.getList();
      }
      this.$emit('refreshPaymentMethodsList',true);
    },
    setPMid(id) {
      this.transaction.meta.payment_method_id = id;
    },
    handleCreateSite() {
      this.editing = false;
      this.$nextTick(() => {
        this.$refs["transaction_form"].clearValidate();
      });
    },
    async handleSubmit() {
      this.$refs["siteForm"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.processing = true;
          this.transaction.holder_type = this.wallet.holder_type;
          this.transaction.holder_id = this.wallet.holder_id;
          this.transaction.slug = this.wallet.slug;
          try {
            // Appel à l'API pour le dépôt
            let transaction_type = this.transactionType;
            const response = await this.$axios.post(
              `/elixio-wallet/${transaction_type}`,
              this.transaction
            );
            if (response.data.success) {
              this.$emit("transactionCreated", response.data);
              this.$notify({
                title: this.$t("WALLET.SUCCESS"),
                message: this.$t("WALLET.DEMANDE_SUCCESS"),
                type: "success",
              });
              // Vider le formulaire
              this.transaction.amount = 0;
              this.transaction.meta.label = "";
            } else {
              this.$notify.error({
                title: this.$t("WALLET.ERROR"),
                message: this.$t("WALLET.DEPOSIT_FAILED"),
              });
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading = false;
            this.processing = false;
          }
        } else {
          this.$message({
            type: "danger",
            message: this.$t("WALLET.INVALID_FORM"),
          });
        }
      });
    },
  },
  computed: {
    wallet_decimal_places() {
      return this.wallet.decimal_places ?? 2;
    },
  },
};
</script>
