import Vue from "vue";
import axios from "axios";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/style.scss";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
Vue.prototype.$axios = axios;

import {
  Button,
  Card,
  Table,
  TabPane,
  Form,
  FormItem,
  InputNumber,
  Divider,
  TableColumn,
  Tabs,
  Badge,
  Drawer,
  Option,
  Select,
  Loading,
  MessageBox,
  Message,
  Notification,
  Checkbox,
  Radio
} from "element-ui";

Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Card.name, Card);
Vue.component(Table.name, Table);
Vue.component(TabPane.name, TabPane);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Tabs.name, Tabs);
Vue.component(Drawer.name, Drawer);
Vue.component(Option.name, Option);
Vue.component(Badge.name, Badge);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Divider.name, Divider);
Vue.component(InputNumber.name, InputNumber);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Radio.name, Radio);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

export default Vue;
