<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="m19.5 4.5l-15 15m0 0h11.25m-11.25 0V8.25"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "ArrowDownLeft",
};
</script>
