<template>
  <div class="wallet-dashboard">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <el-tabs v-model="activePaneName" @tab-click="handleClick">
      <el-tab-pane :label="$t('WALLET.WALLET')" name="wallet" v-show="!loading">
        <div class="has-wallet" v-if="hasWallet">
          <div class="row">
            <div :class="onlineDisplay ? 'col-lg-3 col-md-6 col-sm-12' : 'row'">
              <wallet
                :wallet="currentWallet" 
                @needRefresh="refreshData"
                @refreshPaymentMethodsList="refreshPaymentMethodsList"
              />
            </div>
            <div :class="onlineDisplay ? 'col-lg-9 col-md-6 col-sm-12' : 'row'">
              <el-card>
                <all-transactions-list
                  ref="transactionList"
                  :wallet-id="currentWallet.id"
                  @transactionStatusUpdated="transactionStatusUpdated($event)"
                />
                <!-- <wallet-transactions-list
                  ref="transactionList"
                  :wallet-id="currentWallet.id"
                /> -->
              </el-card>
            </div>
          </div>
        </div>
        <div v-else class="no-wallet-found d-flex align-items-center">
            <no-wallet
              @onWalletCreated="refreshData"
              :holder-type="holderType"
              :holder-id="holderId"
            />
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('WALLET.PAYMENT_METHODS')" name="payment_methods">
        <payment-methods ref="paymentMethods" :holder-type="holderType" :holder-id="holderId" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Vue from "./main";
import WalletTransactionsList from "./components/lists/WalletTransactionsList.vue";
import AllTransactionsList from "./components/lists/AllTransactionsList.vue";
import NoWallet from "./components/NoWallet.vue";
import Wallet from "./components/Wallet.vue";
import PaymentMethods from "./components/PaymentMethods.vue";
import { STATUS_COMPLETED } from "./constants/common";

export default {
  name: "ElixioWalletComponent",
  components: {
    // WalletTransactionsList,
    AllTransactionsList,
    Wallet,
    NoWallet,
    PaymentMethods,
  },
  props: {
    holderType: {
      type: String,
      require: true,
    },
    holderId: {
      type: [String, Number],
      require: true,
    },
    onlineDisplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activePaneName: "wallet",
      wallets: [],
      currentWallet: {},
      loading: false,
      hasWallet: false,
      statusCompleted:STATUS_COMPLETED
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    refreshData() {
      this.getWalletsList();
      // Actualiser la liste des transactions
      if (this.$refs.transactionList) {
        this.$refs.transactionList.refreshList();
      }
    },
    transactionStatusUpdated(status){
      // Actualiser le wallet uniquement si le statut est confirmé
      if (status==this.statusCompleted) {
        this.getWalletsList();
      }
    },
    refreshPaymentMethodsList(){
      // Actualiser la liste des moyens de paiement
      if (this.$refs.paymentMethods) {
        this.$refs.paymentMethods.refreshData();
      }
    },
    async getWalletsList() {
      try {
        this.loading = true;
        let params = {
          holder_type: this.holderType,
          holder_id: this.holderId,
        };
        const response = await this.$axios.get("/elixio-wallet/wallets", {
          params,
        });
        this.wallets = response.data.data;

        this.hasWallet = this.wallets.length > 0;

        this.currentWallet = this.hasWallet ? this.wallets[0] : null;

        this.currentWallet = this.wallets[0];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getWalletsList();
  },
};
</script>
