<template>
  <div>
    <div class="page-wrapper-table">
      <h3>{{ $t("WALLET.SELECT_PAYMENTMETHOD") }}</h3>
      <div class="page-wrapper-table-header">
        <base-input
          v-model="query"
          type="search"
          prepend-icon="fas fa-search"
          :placeholder="$t('WALLET.SEARCH')"
          clearable
        />
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="paymentMethods"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>

            <el-table-column
              :label="$t('WALLET.SELECT')"
              prop="id"
              sortable="custom"
              min-width="150"
            >
              <template v-slot="{ row }">
                <el-radio v-model="selectedId" :label="row.id" @change="onSelection" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('WALLET.account_number')"
              prop="status"
              min-width="350"
            >
              <template v-slot="{ row }">
                {{ row.details.account_number }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.PAYMENT_METHOD_HOLDER')"
              prop="holder"
              min-width="300"
            >
              <template v-slot="{ row }">
                {{
                  row.details.holder.firstname +
                  " " +
                  row.details.holder.lastname
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("WALLET.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("WALLET.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import BasePagination from "../atoms/BasePagination.vue";

export default {
  name: "PaymentMethodsSelectionList",

  props: {
    holderType: {
      type: String,
      require: true,
    },
    holderId: {
      type: [String, Number],
      require: true,
    },
  },

  components: {
    BasePagination,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      selectedId:null,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      paymentMethods: [],
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    onSelection(){
        this.$emit('selected', this.selectedId);
    },
    currentUserCan(permission) {
      //return this.$currentUserCan(permission);
      return true;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          holder_type: this.holderType,
          holder_id: this.holderId,
          sort: this.sort,
          "filter.search": this.query,
          "page.number": this.pagination.currentPage,
          "page.size": this.pagination.perPage,
        };
        const response = await this.$axios.get(
          "/elixio-wallet/payment-methods",
          { params }
        );
        this.paymentMethods = response.data.data;
        this.total = response.data.total;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>
