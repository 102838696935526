<template>
  <div :class="'badge badge-' + badgeType">
    <arrow-down-icon v-if="badgeType == 'success'" />
    <arrow-up-icon v-else />
  </div>
</template>

<script>
import ArrowDownIcon from "../../Icons/ArrowDownIcon.vue";
import ArrowUpIcon from "../../Icons/ArrowUpIcon.vue";
import { DEPOSIT, WITHDRAW } from "../../constants/common";

export default {
  name: "InOutBadge",
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return [DEPOSIT, WITHDRAW].includes(value);
      },
    },
  },
  computed: {
    badgeType() {
      switch (this.type) {
        case DEPOSIT:
          return "success";
        case WITHDRAW:
          return "danger";
        default:
          return "default";
      }
    },
  },
};
</script>
