export const DEPOSIT = "deposit";
export const WITHDRAW = "withdraw";
export const STATUS_DRAFT = "draft";
export const STATUS_AWAITING_SIGNATURE = "awaiting_signature";
export const STATUS_SIGNATURE_DELINED = "signature_declined";
export const STATUS_AWAITING_ADMIN_VALIDATION = "awaiting_validation";
export const STATUS_ADMIN_REFUSED = "admin_refused";
export const STATUS_PENDING = "pending";
export const STATUS_BANK_REFUSED = "bank_refused";
export const STATUS_COMPLETED = "completed";
export const TRANSACTION_STATUS_LIST = [
  '',
  STATUS_DRAFT,
  STATUS_AWAITING_SIGNATURE,
  STATUS_SIGNATURE_DELINED,
  STATUS_AWAITING_ADMIN_VALIDATION,
  STATUS_ADMIN_REFUSED,
  STATUS_PENDING,
  STATUS_BANK_REFUSED,
  STATUS_COMPLETED
];
export const MAX_AMOUNT = 100000000;
export const MIN_AMOUNT = 10;
export const POSSIBLE_HOLDER_ATTRIBUTES =[
  "name",
  "firstname",
  "lastname",
  "company_name",
];