<template>
  <div class="content-inputs mb-3">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <el-form
      ref="paymentMethodForm"
      :model="paymentmethod"
      label-position="left"
      @submit.prevent="handleSubmit"
    >
      <div class="account">
        <h3>{{ $t("WALLET.PAYMENT_METHOD_ACCOUNT") }}</h3>
        <div class="row">
          <div class="col-lg-6">
            <el-form-item
              :label="$t('WALLET.account_number')"
              prop="details.account_number"
              :rules="[
                {
                  required: true,
                  message: this.$t('WALLET.account_number_required'),
                },
              ]"
            >
              <el-input
                v-model="paymentmethod.details.account_number"
                :placeholder="$t('WALLET.account_number')"
              />
            </el-form-item>
          </div>
          <div class="col-lg-6">
            <el-form-item
              :label="$t('WALLET.bank_code')"
              prop="details.bank_code"
              :rules="[
                {
                  required: true,
                  message: this.$t('WALLET.bank_code_required'),
                },
              ]"
            >
              <el-input
                v-model="paymentmethod.details.bank_code"
                :placeholder="$t('WALLET.bank_code')"
              />
            </el-form-item>
          </div>
          <div class="col-lg-12">
            <el-form-item
              :label="$t('WALLET.iban')"
              prop="details.iban"
              :rules="[
                { required: true, message: this.$t('WALLET.iban_required') },
              ]"
            >
              <el-input
                v-model="paymentmethod.details.iban"
                :placeholder="$t('WALLET.iban')"
              />
            </el-form-item>
          </div>
        </div>
      </div>

      <div class="holder">
        <el-divider />
        <h3>{{ $t("WALLET.PAYMENT_METHOD_HOLDER") }}</h3>
        <div class="row">
          <div class="col-lg-6">
            <el-form-item
              :label="$t('WALLET.firstname')"
              prop="details.holder.firstname"
              :rules="[
                {
                  required: true,
                  message: this.$t('WALLET.firstname_required'),
                },
              ]"
            >
              <el-input
                v-model="paymentmethod.details.holder.firstname"
                :placeholder="$t('WALLET.firstname')"
              />
            </el-form-item>
          </div>
          <div class="col-lg-6">
            <el-form-item
              :label="$t('WALLET.lastname')"
              prop="details.holder.lastname"
              :rules="[
                {
                  required: true,
                  message: this.$t('WALLET.lastname_required'),
                },
              ]"
            >
              <el-input
                v-model="paymentmethod.details.holder.lastname"
                :placeholder="$t('WALLET.lastname')"
              />
            </el-form-item>
          </div>

          <div class="col-lg-6">
            <el-form-item :label="$t('WALLET.address')">
              <el-input
                v-model="paymentmethod.details.holder.address"
                :placeholder="$t('WALLET.address')"
              />
            </el-form-item>
          </div>

          <div class="col-lg-6">
            <el-form-item :label="$t('WALLET.phone')">
              <el-input
                v-model="paymentmethod.details.holder.phone"
                :placeholder="$t('WALLET.phone')"
              />
            </el-form-item>
          </div>

          <div class="col-lg-12">
            <el-form-item
              :label="$t('WALLET.email')"
              prop="details.holder.email"
              :rules="[
                { required: true, message: $t('WALLET.email_required') },
                { type: 'email', message: $t('WALLET.email_correct') },
              ]"
            >
              <el-input
                v-model="paymentmethod.details.holder.email"
                :placeholder="$t('WALLET.email')"
              />
            </el-form-item>
          </div>
        </div>
      </div>

      <!-- Section Signataires -->
      <div class="signers mb-3">
        <el-divider />
        <h3>{{ $t("WALLET.PAYMENT_METHOD_SIGNERS") }}</h3>

        <!-- Bouton pour ajouter un signataire -->
        <el-button
          class="my-3"
          type="secondary"
          icon="el-icon-plus"
          @click="addSigner"
          style="margin-top: 10px"
        >
          {{ $t("WALLET.ADD_SIGNER") }}
        </el-button>

        <!-- Tableau des signataires -->
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush form-table"
            header-row-class-name="thead-light"
            :data="paymentmethod.signers"
            border
          >
            <el-table-column
              :label="$t('WALLET.email')"
              prop="email"
              min-width="250"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'signers.' + scope.$index + '.email'"
                  :rules="[
                    { required: true, message: $t('WALLET.email_required') },
                    { type: 'email', message: $t('WALLET.email_correct') },
                  ]"
                >
                  <el-input
                    v-model="scope.row.email"
                    :placeholder="$t('WALLET.email')"
                  />
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.firstname')"
              prop="firstname"
              min-width="250"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'signers.' + scope.$index + '.email'"
                  :rules="[
                    {
                      required: true,
                      message: $t('WALLET.firstname_required'),
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.firstname"
                    :placeholder="$t('WALLET.firstname')"
                  />
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.lastname')"
              prop="lastname"
              min-width="250"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'signers.' + scope.$index + '.email'"
                  :rules="[
                    { required: true, message: $t('WALLET.lastname_required') },
                  ]"
                >
                  <el-input
                    v-model="scope.row.lastname"
                    :placeholder="$t('WALLET.lastname')"
                  />
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.address')"
              prop="email"
              min-width="250"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.address"
                  :placeholder="$t('WALLET.address')"
                />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('WALLET.phone')"
              prop="email"
              min-width="250"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.phone"
                  :placeholder="$t('WALLET.phone')"
                />
              </template>
            </el-table-column>

            <!-- Colonne pour supprimer un signataire -->
            <el-table-column
              :label="$t('WALLET.actions')"
              prop="email"
              min-width="100"
            >
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="removeSigner(scope.$index)"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <el-button
        type="primary"
        class="align-items-center my-3"
        :disabled="loading || processing"
        @click="handleSubmit"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
        {{
          operationType == "edit"
            ? $t("WALLET.EDIT_PAYMENTMETHOD")
            : $t("WALLET.ADD_THE_PAYMENTMOTHOD")
        }}
      </el-button>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "PaymentMethodForm",
  props: {
    holderType: {
      type: String,
      required: true,
    },
    holderId: {
      type: [String, Number],
      required: true,
    },
    operationType: {
      type: String,
      default: "create",
    },
    paymentMethodToEdit: {
      type: Object,
      default: () => ({
        id: "",
        holder_type: "",
        holder_id: "",
        type: "bank_account",
        details: {
          account_number: "",
          bank_code: "",
          iban: "",
          holder: {
            firstname: "",
            lastname: "",
            address: "",
            phone: "",
            email: "",
          },
        },
        signers: [],
      }),
    },
  },
  data() {
    return {
      paymentmethod: this.createEmptyPaymentMethod(),
      loading: false,
      processing: false,
    };
  },
  watch: {
    paymentMethodToEdit: {
      handler(val) {
        if (val) {
          this.paymentmethod = this.clonePaymentMethod(val);
        }
      },
      deep: true,
    },
    operationType: {
      handler(val) {
        if(val){
          if (val == "create") {
            this.initForm();
          }else {
            this.initForm();
            this.paymentmethod = this.clonePaymentMethod(this.paymentMethodToEdit);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    // Méthode pour ajouter un signataire
    addSigner() {
      this.paymentmethod.signers.push({
        firstname: "",
        lastname: "",
        address: "",
        phone: "",
        email: "",
      });
    },
    // Méthode pour supprimer un signataire
    removeSigner(index) {
      this.paymentmethod.signers.splice(index, 1);
    },

    initForm() {
      this.paymentmethod = this.createEmptyPaymentMethod();
      if (this.$refs.paymentMethodForm) {
        this.$refs.paymentMethodForm.resetFields();
      }
    },
    createEmptyPaymentMethod() {
      return {
        holder_type: "",
        holder_id: "",
        type: "bank_account",
        details: {
          account_number: "",
          bank_code: "",
          iban: "",
          holder: {
            firstname: "",
            lastname: "",
            address: "",
            phone: "",
            email: "",
          },
        },
        signers: [],
      };
    },

    clonePaymentMethod(paymentMethod) {
      return JSON.parse(JSON.stringify(paymentMethod));
    },


    async handleSubmit() {
      this.$refs["paymentMethodForm"].validate(async (valid) => {
        if (valid) {
          let editing = this.operationType == "edit";
          this.loading = true;
          this.processing = true;
          this.paymentmethod.holder_type = this.holderType;
          this.paymentmethod.holder_id = this.holderId;
          try {
            let response;
            if (editing) {
              // Appel à l'API pour la modification
              response = await this.$axios.put(
                `/elixio-wallet/payment-methods/${this.paymentmethod.id}`,
                this.paymentmethod
              );
            } else {
              // Appel à l'API pour la création
              response = await this.$axios.post(
                "/elixio-wallet/payment-methods",
                this.paymentmethod
              );
            }

            if (response.data.success) {
              this.$emit("paymentmethodCreated", response.data);
              this.$notify({
                title: this.$t("WALLET.SUCCESS"),
                message: editing
                  ? this.$t("WALLET.PAYMENT_METHOD_UPDATED")
                  : this.$t("WALLET.PAYMENT_METHOD_CREATED"),
                type: "success",
              });

              // Vider le formulaire
              this.initForm();
            } else {
              this.$notify.error({
                title: this.$t("WALLET.ERROR"),
                message: editing
                  ? this.$t("WALLET.PAYMENT_METHOD_UPDATE_FAILED")
                  : this.$t("WALLET.PAYMENT_METHOD_CREATION_FAILED"),
              });
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading = false;
            this.processing = false;
          }
        } else {
          this.$message({
            type: "danger",
            message: this.$t("WALLET.INVALID_FORM"),
          });
        }
      });
    },
  },
  mounted() {
    this.initForm();
    if (this.paymentMethodToEdit) {
      this.paymentmethod = this.clonePaymentMethod(this.paymentMethodToEdit);
    }
  },
};
</script>
