<template>
  <div :class="'badge badge-' + badgeType" class="d-flex align-items-center">
    <arrow-up-right v-if="isOut" class="mr-2"/>
    <arrow-down-left v-else class="mr-2"/>
    <amount-badge :type="type" :amount="amount" :symbol="symbol"/>
  </div>
</template>
  
  <script>
  import ArrowUpRight from '../../Icons/ArrowUpRight.vue';
  import ArrowDownLeft from '../../Icons/ArrowDownLeft.vue';
  import AmountBadge from './AmountBadge.vue';

  export default {
    name: "BalanceBadge",
    components: {
        ArrowUpRight, ArrowDownLeft, AmountBadge
    },
    props: {
      type:{
        type:String,
        required:true
      },
      amount: {
        type: [Number,String],
        required: true
      },
    symbol: {
      type: String,
      default: '$'
    },
    },
    computed: {
      isOut() {
        return this.type == 'withdraw';
      },
      badgeType() {
        return this.type == 'withdraw' ? "danger" : "success"
      },
    },
  };
  </script>