<template>
    <el-table
      class="object-detail-table"
      :data="formattedData"
      :show-header="false"
      stripe
      border
    >
      <el-table-column
        prop="label"
        :label="$t('WALLET.FIELD')"
        width="150"
      >
        <template slot-scope="scope">
          <strong>{{ scope.row.label }}</strong>
        </template>
      </el-table-column>
      <el-table-column
        prop="value"
        :label="$t('WALLET.VALUE')"
        align="left"
      >
      <template slot-scope="scope">
        <div v-if="isTransactionStatus(scope.row.value)">
          <status-badge :status="scope.row.value" />
        </div>
        <div v-else-if="isDate(scope.row.value)">
          {{ $formatDate(scope.row.value) }}
        </div>
        <div v-else>
          {{ scope.row.value }}
        </div>
      </template>
      </el-table-column>
    </el-table>
  </template>
  
  <script>
  import { TRANSACTION_STATUS_LIST } from "../../constants/common";
  import StatusBadge from "./StatusBadge.vue";
  export default {
    name: "ObjectDetails",
    components:{
      StatusBadge
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      withTrad:{
        type: Boolean,
        default:true
      }
    },
    data(){
      return{
        TRANSACTION_STATUS_LIST:TRANSACTION_STATUS_LIST
      }
    },
    computed: {
      formattedData() {
        // Conversion de l'objet en tableau de paires clé-valeur
        return Object.keys(this.data).map(key => ({
          label:this.withTrad ? this.$t(`WALLET.${key}`) : key, // Trad ou clé elle-même
          value: this.data[key] || '',     // La valeur
        }));
      },
    },
    methods: {
      isTransactionStatus(value) {
        // Vérifie si la valeur est dans TRANSACTION_STATUS_LIST
        return value!='' && this.TRANSACTION_STATUS_LIST.includes(value);
      },
      isDate(value) {
        // Vérifie si la valeur correspond au format ISO 8601
        const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/;
        return value!='' && iso8601Regex.test(value);
      }

    },
  };
  </script>