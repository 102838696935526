<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="m7 11l5-5m0 0l5 5m-5-5v12"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "ArrowUpIcon",
};
</script>
